<template>
  <div style="min-height: 750px;" v-if="d_usrShpcrtBundProPayWdm && d_usrShpcrtBundProWdm && d_usrShpcrtBundWdm">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['order_list'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row style="font-size: 16px;">
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
        <b-row style="margin: 3px; padding-bottom: 10px;">
          <b-col sm="12" lg="9">
            <strong style="font-size: 30px;"> {{ StoreLangTranslation.data['order_list'][StoreLang] }} </strong>
            <span> ( {{ d_shopOrderList.length }} ) {{ StoreLangTranslation.data['product'][StoreLang] }} </span>
          </b-col>
          <b-col sm="12" lg="3" style="text-align: right;"></b-col>
        </b-row>

       <b-card no-body>
        <b-card-header header-bg-variant="white" class="p-1">
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="8" style="padding: 2px;">
              <strong> {{ StoreLangTranslation.data['filter_field'][StoreLang] }} </strong>
            </b-col>
            <b-col sm="12" lg="4" style="text-align: right;">
              <b-form-select v-model="d_filterMode">
                <option value="username">
                  {{ StoreLangTranslation.data['search_by_user'][StoreLang] }}
                </option>
                <option value="query">
                  {{ StoreLangTranslation.data['search_by_query'][StoreLang] }}
                </option>
              </b-form-select>
            </b-col>
          </b-row>
        </b-card-header>

        <b-row v-if="d_filterMode === 'username'" style="margin: 3px;">
          <b-col sm="12" lg="3">
            <strong> {{ StoreLangTranslation.data['user_search'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="9">
            <user-search :p_searchResult="d_userSearchResult" :p_searchMode="d_searchMode"></user-search>
          </b-col>
        </b-row>

        <b-row v-if="d_filterMode === 'username'" style="margin: 3px;">
          <b-col sm="12" lg="3">
            <strong> {{ StoreLangTranslation.data['bundle_group'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="9">
              <b-form-select v-if="d_shopWdmrId && d_productList" v-model="d_selectedBundleGroup">
                <option v-for="(bundle_group_id, bundle_group_index) in d_productList.parameters[d_shopWdmrId].groups.param_order" :value="bundle_group_id">
                   {{ d_productList.parameters[d_shopWdmrId].groups.parameters[bundle_group_id]['name'][StoreLang].toLocaleUpperCase() }}
                </option>
              </b-form-select>
          </b-col>
        </b-row>
        <b-row v-if="d_filterMode === 'username' && d_selectedBundleGroup !== ''" style="margin: 3px;">
          <b-col sm="12" lg="3">
            <strong> {{ StoreLangTranslation.data['bundle'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="9">
              <b-form-select v-model="d_selectedBundleId">
                <option v-for="(bundle_id, bundle_id_index) in d_productList.parameters[d_shopWdmrId].groups.parameters[d_selectedBundleGroup].id_list" :value="bundle_id">
                    {{ d_productList.parameters[d_shopWdmrId].parameters[bundle_id].data.data['0']['1']['lang'][StoreLang] }} 
                </option>
              </b-form-select>
          </b-col>
        </b-row>
        <b-row v-if="d_filterMode === 'username' && d_selectedBundleId !== '' && d_userSearchResult.selected_index !== ''" style="margin: 3px;">
          <b-col sm="12" lg="3">
          </b-col>
          <b-col sm="12" lg="9">
            <b-button @click="f_userShopCartBundleAdd()" variant="outline-success" :disabled="d_addToShopcartLoading">
              <i class="fa fa-plus-circle"></i>
              {{ StoreLangTranslation.data['add_to_shopping_cart'][StoreLang] }}
              <b-spinner v-if="d_addToShopcartLoading" variant="primary" label="Spinning"></b-spinner>
            </b-button>
          </b-col>
        </b-row>

        <template v-if="d_filterMode === 'query'">
          <template v-for="(group, group_ind) in d_filterData.paramorder">
            <b-row :style="'margin: 3px; border-bottom: solid 1px #00e4ff; background-color: ' + d_filterData.parameters[group].background_color">
              <!-- 
                  <b-col sm="12" lg="1" style="padding: 2px;">
                    {{ StoreLangTranslation.data['filter_operation_type'][StoreLang] }}
                    <select class="form-control" v-model="d_filterData.filter_operation_type" style="font-size: 11px; padding: 2px;">
                      <option value="and">{{ StoreLangTranslation.data['and'][StoreLang] }}</option>
                      <option value="or">{{ StoreLangTranslation.data['or'][StoreLang] }}</option>
                    </select>
                  </b-col>
               -->
              <b-col sm="12" lg="2" style="padding: 10px; font-size: 12px;">
                <strong> {{ StoreLangTranslation.data[group][StoreLang] }} </strong>
              </b-col>
              <b-col sm="12" lg="10" style="padding: 2px;">
                <b-row>
                  <template v-for="(param, param_ind) in d_filterData.parameters[group].paramorder">
                    <b-col sm="12" lg="3">
                      <small style="font-size: 12px;"> {{ StoreLangTranslation.data[param] ? StoreLangTranslation.data[param][StoreLang] : param + '..' }} </small>
                      <template v-if="d_filterData.parameters[group].parameters[param].start !== undefined">
                        <select class="form-control" v-model="d_filterData.parameters[group].parameters[param].use" style="font-size: 12px; padding: 2px;">
                          <option value="no">{{ StoreLangTranslation.data['no'][StoreLang] }}</option>
                          <option value="yes">{{ StoreLangTranslation.data['yes'][StoreLang] }}</option>
                        </select>
                        <div v-if="d_filterData.parameters[group].parameters[param].use === 'yes'">
                          {{ StoreLangTranslation.data['begin_date'][StoreLang] }}
                          <b-form-input class="form-control" type="date" v-model="d_filterData.parameters[group].parameters[param].start" style="font-size: 12px; padding: 2px;"></b-form-input>
                        </div>
                        <div v-if="d_filterData.parameters[group].parameters[param].use === 'yes'">
                          {{ StoreLangTranslation.data['end_date'][StoreLang] }}
                          <b-form-input class="form-control" type="date" v-model="d_filterData.parameters[group].parameters[param].end" style="font-size: 12px; padding: 2px;"></b-form-input>
                        </div>
                      </template>
                      <template v-else>
                        <select class="form-control" v-model="d_filterData.parameters[group].parameters[param].value" :title="d_filterData.parameters[group].wdm['0']['parameters'][d_filterData.parameters[group].parameters[param].wdm_param].name.translation[StoreLang]" style="font-size: 12px; padding: 2px;">
                          <option value="">{{ StoreLangTranslation.data['none'][StoreLang] }}</option>
                          <option value="all">{{ StoreLangTranslation.data['all'][StoreLang] }}</option>
                          <option v-for="(op, op_index) in d_filterData.parameters[group].wdm['0']['parameters'][d_filterData.parameters[group].parameters[param].wdm_param].options" :value="op.value">
                            {{ op.translation[StoreLang] }}
                          </option>
                        </select>              
                      </template>
                    </b-col>
                  </template>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </template>
      </b-card>

       <b-card no-body>
        <b-card-header header-bg-variant="white" class="p-1">
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4" style="padding: 2px;">
              <strong> {{ StoreLangTranslation.data['results'][StoreLang] }} </strong>
            </b-col>
            <b-col sm="12" lg="2" style="padding: 2px;">
              <b-form-checkbox v-model="d_showDates">
                 {{ StoreLangTranslation.data['show_dates'][StoreLang] }} 
              </b-form-checkbox>
            </b-col>
            <b-col sm="12" lg="6" style="text-align: right;">
              <template v-if="d_dataPrepareType === 'wdmr_list_id'">
                <b-button variant="outline-primary" v-if="d_pagination.next_page_eligible === 'yes'" @click="f_userShopOrderList('filter_data', 'desc', [], true)">
                  {{ d_pagination.wdmr_list_id  }} / {{ d_pagination.wdmr_list_id_count }}
                  <i class="fa fa-angle-double-right"></i>
                </b-button>
              </template>
              <template v-else-if="d_dataPrepareType === 'data_profile'">
                <b-button variant="white" v-if="d_pagination.next_page_eligible === 'yes' && d_pagination.first_field_option_list_id !== d_pagination.field_option_list_id" @click="f_userShopOrderList('filter_data', 'desc', [], true)"> <i class="fa fa-angle-left"></i> </b-button>
                <b-button variant="white" v-if="d_pagination.next_page_eligible === 'yes' && d_pagination.last_field_option_list_id !== d_pagination.field_option_list_id" @click="f_userShopOrderList('filter_data', 'asc', [], true)"> <i class="fa fa-angle-right"></i> </b-button>
              </template>
              <b-button v-if="d_pagination.service_triggered === 'yes'" @click="f_resetFilterData()" variant="white">reset</b-button>
              <b-button v-if="d_pagination.service_triggered === 'no'" :disabled="d_filterLoading" @click="f_userShopOrderList('filter_data', 'asc', [], true)" variant="white">
                <template v-if="d_filterLoading">
                  <b-spinner variant="primary" label="Spinning"></b-spinner>
                </template>
                 {{ StoreLangTranslation.data['search'][StoreLang] }} 
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
          <template v-if="d_filterLoading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </template>
          <template v-else>
            <b-row>
              <b-col sm="12" lg="12">
                <!-- 
                  d_shopOrderList = [
                    {
                      "product_list": [],
                      "user_shopcart_bundle_data": {},
                      "user_shop_bundle_data": {},
                    }
                  ]
                 -->
                 <b-card no-body v-for="(user_item, user_item_index) in d_shopOrderList">
                  <b-card-header header-bg-variant="white" class="p-1">
                    <b-row style="margin: 0px; background-color: #93ffe4;" v-b-toggle="'collapse_' + user_item_index">
                      <b-col sm="12" lg="3">
                        <i class="fa fa-user"></i>
                        {{ user_item.user_firstname.val }} {{ user_item.user_lastname.val }}
                      </b-col>
                      <b-col sm="12" lg="3">
                        <i class="fa fa-envelope"></i>
                        {{ user_item.user_mail.val }}
                      </b-col>
                      <b-col sm="12" lg="2">
                        <i class="fa fa-venus"></i>
                        {{ user_item.user_gender && user_item.user_gender.val && user_item.user_gender.val.translation ? user_item.user_gender.val.translation[StoreLang] : '' }}
                      </b-col>
                      <b-col sm="12" lg="2">
                        <i class="fa fa-mobile-phone"></i>
                        {{ user_item.user_phone.val }}
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-collapse :id="'collapse_' + user_item_index" :visible="false">
                    <b-card-body>
                      <template v-for="(bundle_item, bundle_item_index) in user_item.bundle_list" v-if="f_searchUserShopBundle(bundle_item)">
                         <b-row @mouseover="d_hoverIndex = bundle_item_index" @mouseleave="d_hoverIndex = ''" :style="StoreDevice.isMobile ? 'cursor: pointer; border-bottom: solid 1px #dcdcdc; font-weight: 900; background: #f7e48f;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc; font-weight: 900; background: #f7e48f;'">
                          <b-col sm="12" lg="7">
                            <template v-if="bundle_item.user_shop_bundle_data.data && bundle_item.user_shop_bundle_data.data['0'] && bundle_item.user_shop_bundle_data.data['0']['1'] && bundle_item.user_shop_bundle_data.data['0']['1']['lang'] && bundle_item.user_shop_bundle_data.data['0']['1']['lang'][StoreLang]">
                              <span :style="d_hoverIndex === bundle_item_index ? ' color: rgb(252, 0, 153, 1);' : ''">{{ bundle_item.user_shop_bundle_data.data['0']['1']['lang'][StoreLang] }}</span>
                            </template>
                            <template v-else-if="bundle_item.user_shop_bundle_data.data && bundle_item.user_shop_bundle_data.data['0'] && bundle_item.user_shop_bundle_data.data['0']['1'] && bundle_item.user_shop_bundle_data.data['0']['1']['val']">
                              <span :style="d_hoverIndex === bundle_item_index ? ' color: rgb(252, 0, 153, 1);' : ''">{{ bundle_item.user_shop_bundle_data.data['0']['1']['val'] }}</span>
                            </template>
                            <span v-if="bundle_item.user_shopcart_bundle_data.data['0'] && bundle_item.user_shopcart_bundle_data.data['0']['8'] && bundle_item.user_shopcart_bundle_data.data['0']['8']['val']" style="color: dodgerblue;">
                              [ {{ f_dateFormat(bundle_item.user_shopcart_bundle_data.data['0']['8']['val'], 'year-month-day') }} ]
                            </span>
                            <span>
                              {{ 'L' + bundle_item.user_shopcart_bundle_data.li + '_' + bundle_item.user_shopcart_bundle_data.id }}
                            </span>

                          </b-col>
                          <b-col sm="12" lg="1">
                            <b-spinner v-if="d_bundlesLoading" variant="primary" size="sm" label="Spinning"></b-spinner>
                          </b-col>
                          <b-col sm="12" lg="2">
                            {{ f_calculateProductTotalPrice(bundle_item.product_list) }}
                          </b-col>
                          <b-col sm="12" lg="2">
                            <template v-if="bundle_item.loading">
                              <b-spinner variant="primary" label="Spinning"></b-spinner>
                            </template>
                            <template v-else>
                              <b-dropdown variant="white" size="sm" style="width: 100%; float: left;">
                                <template slot="button-content">
                                  <template v-if="bundle_item.user_shopcart_bundle_data.data['0']['0'] && bundle_item.user_shopcart_bundle_data.data['0']['0']['val'] && bundle_item.user_shopcart_bundle_data.data['0']['0']['val']['value']">
                                    {{ f_getOptionLabel(bundle_item.user_shopcart_bundle_data.data['0']['0']['val']['value'], d_usrShpcrtBundWdm['0']['parameters']['0']['options']) }}
                                  </template>
                                </template>
                                <b-dropdown-item @click="f_openPaymentScreen(bundle_item.product_list, bundle_item.user_shopcart_bundle_data.client_id)"><i class="fa fa-dollar"></i> {{ StoreLangTranslation.data['product_payment_screen'][StoreLang] }} </b-dropdown-item>

                                <template v-for="(bundle_status, bundle_status_index) in f_getEligibleBundleStatus(bundle_item.user_shopcart_bundle_data)">
                                  <b-dropdown-item @click="f_changeBundleStatus(bundle_status, bundle_item.user_shopcart_bundle_data.client_id, user_item_index, bundle_item_index)"><i class="fa fa-list"></i> {{ bundle_status.translation[StoreLang] }} </b-dropdown-item>
                                </template>
        <!--                         <b-dropdown-item @click="f_userShopcartBundlePaymentApprove(bundle_item.user_shopcart_bundle_data.client_id, bundle_item.product_list)"><i class="fa fa-list"></i> {{ StoreLangTranslation.data['payment_approve'][StoreLang] }} </b-dropdown-item>
                                <b-dropdown-item @click="f_cancelUserShopcartBundle(bundle_item.user_shopcart_bundle_data)"><i class="fa fa-list"></i> {{ StoreLangTranslation.data['cancel'][StoreLang] }} </b-dropdown-item> -->
                              </b-dropdown>
                            </template>
                          </b-col>
                        </b-row>

                        <template v-if="d_showDates">
                          <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                            <b-col sm="12" lg="3">
                              <strong><i class="fa fa-calendar"></i> {{ d_usrShpcrtBundWdm['0']['parameters']['8']['name']['translation'][StoreLang] }}</strong>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <template v-if="bundle_item.user_shopcart_bundle_data.data['0'] && bundle_item.user_shopcart_bundle_data.data['0']['8'] && bundle_item.user_shopcart_bundle_data.data['0']['8']['val']">
                                {{ f_dateFormat(bundle_item.user_shopcart_bundle_data.data['0']['8']['val'], 'year-month-day hh:mm') }}
                              </template>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <strong><i class="fa fa-user"></i> {{ d_usrShpcrtBundWdm['0']['parameters']['14']['name']['translation'][StoreLang] }}</strong>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <template v-if="bundle_item.user_shopcart_bundle_data.data['0'] && bundle_item.user_shopcart_bundle_data.data['0']['14'] && bundle_item.user_shopcart_bundle_data.data['0']['14']['val']">
                                {{ bundle_item.user_shopcart_bundle_data.data['0']['14']['val'] }}
                              </template>
                            </b-col>
                          </b-row>

                          <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                            <b-col sm="12" lg="3">
                              <strong><i class="fa fa-calendar"></i> {{ d_usrShpcrtBundWdm['0']['parameters']['9']['name']['translation'][StoreLang] }}</strong>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <template v-if="bundle_item.user_shopcart_bundle_data.data['0'] && bundle_item.user_shopcart_bundle_data.data['0']['9'] && bundle_item.user_shopcart_bundle_data.data['0']['9']['val']">
                                {{ f_dateFormat(bundle_item.user_shopcart_bundle_data.data['0']['9']['val'], 'year-month-day hh:mm') }}
                              </template>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <strong><i class="fa fa-user"></i> {{ d_usrShpcrtBundWdm['0']['parameters']['15']['name']['translation'][StoreLang] }}</strong>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <template v-if="bundle_item.user_shopcart_bundle_data.data['0'] && bundle_item.user_shopcart_bundle_data.data['0']['15'] && bundle_item.user_shopcart_bundle_data.data['0']['15']['val']">
                                {{ bundle_item.user_shopcart_bundle_data.data['0']['15']['val'] }}
                              </template>
                            </b-col>
                          </b-row>
                          
                          <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                            <b-col sm="12" lg="3">
                              <strong><i class="fa fa-calendar"></i> {{ d_usrShpcrtBundWdm['0']['parameters']['10']['name']['translation'][StoreLang] }}</strong>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <template v-if="bundle_item.user_shopcart_bundle_data.data['0'] && bundle_item.user_shopcart_bundle_data.data['0']['10'] && bundle_item.user_shopcart_bundle_data.data['0']['10']['val']">
                                {{ f_dateFormat(bundle_item.user_shopcart_bundle_data.data['0']['10']['val'], 'year-month-day hh:mm') }}
                              </template>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <strong><i class="fa fa-user"></i> {{ d_usrShpcrtBundWdm['0']['parameters']['16']['name']['translation'][StoreLang] }}</strong>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <template v-if="bundle_item.user_shopcart_bundle_data.data['0'] && bundle_item.user_shopcart_bundle_data.data['0']['16'] && bundle_item.user_shopcart_bundle_data.data['0']['16']['val']">
                                {{ bundle_item.user_shopcart_bundle_data.data['0']['16']['val'] }}
                              </template>
                            </b-col>
                          </b-row>
                          
                          <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                            <b-col sm="12" lg="3">
                              <strong><i class="fa fa-calendar"></i> {{ d_usrShpcrtBundWdm['0']['parameters']['11']['name']['translation'][StoreLang] }}</strong>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <template v-if="bundle_item.user_shopcart_bundle_data.data['0'] && bundle_item.user_shopcart_bundle_data.data['0']['11'] && bundle_item.user_shopcart_bundle_data.data['0']['11']['val']">
                                {{ f_dateFormat(bundle_item.user_shopcart_bundle_data.data['0']['11']['val'], 'year-month-day hh:mm') }}
                              </template>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <strong><i class="fa fa-user"></i> {{ d_usrShpcrtBundWdm['0']['parameters']['17']['name']['translation'][StoreLang] }}</strong>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <template v-if="bundle_item.user_shopcart_bundle_data.data['0'] && bundle_item.user_shopcart_bundle_data.data['0']['17'] && bundle_item.user_shopcart_bundle_data.data['0']['17']['val']">
                                {{ bundle_item.user_shopcart_bundle_data.data['0']['17']['val'] }}
                              </template>
                            </b-col>
                          </b-row>
                          
                          <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                            <b-col sm="12" lg="3">
                              <strong><i class="fa fa-calendar"></i> {{ d_usrShpcrtBundWdm['0']['parameters']['12']['name']['translation'][StoreLang] }}</strong>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <template v-if="bundle_item.user_shopcart_bundle_data.data['0'] && bundle_item.user_shopcart_bundle_data.data['0']['12'] && bundle_item.user_shopcart_bundle_data.data['0']['12']['val']">
                                {{ f_dateFormat(bundle_item.user_shopcart_bundle_data.data['0']['12']['val'], 'year-month-day hh:mm') }}
                              </template>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <strong><i class="fa fa-user"></i> {{ d_usrShpcrtBundWdm['0']['parameters']['18']['name']['translation'][StoreLang] }}</strong>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <template v-if="bundle_item.user_shopcart_bundle_data.data['0'] && bundle_item.user_shopcart_bundle_data.data['0']['18'] && bundle_item.user_shopcart_bundle_data.data['0']['18']['val']">
                                {{ bundle_item.user_shopcart_bundle_data.data['0']['18']['val'] }}
                              </template>
                            </b-col>
                          </b-row>
                          
                          <b-row style="margin: 3px; border-bottom: solid 1px whitesmoke;">
                            <b-col sm="12" lg="3">
                              <strong><i class="fa fa-calendar"></i> {{ d_usrShpcrtBundWdm['0']['parameters']['13']['name']['translation'][StoreLang] }}</strong>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <template v-if="bundle_item.user_shopcart_bundle_data.data['0'] && bundle_item.user_shopcart_bundle_data.data['0']['13'] && bundle_item.user_shopcart_bundle_data.data['0']['13']['val']">
                                {{ f_dateFormat(bundle_item.user_shopcart_bundle_data.data['0']['13']['val'], 'year-month-day hh:mm') }}
                              </template>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <strong><i class="fa fa-user"></i> {{ d_usrShpcrtBundWdm['0']['parameters']['19']['name']['translation'][StoreLang] }}</strong>
                            </b-col>
                            <b-col sm="12" lg="3">
                              <template v-if="bundle_item.user_shopcart_bundle_data.data['0'] && bundle_item.user_shopcart_bundle_data.data['0']['19'] && bundle_item.user_shopcart_bundle_data.data['0']['19']['val']">
                                {{ bundle_item.user_shopcart_bundle_data.data['0']['19']['val'] }}
                              </template>
                            </b-col>
                          </b-row>
                        </template>
                        
                        <template v-for="(product_item, product_item_index) in bundle_item.product_list">
                          <b-row :style="StoreDevice.isMobile ? 'cursor: pointer; border-bottom: solid 1px #dcdcdc; margin-left: 10px;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc; margin-left: 10px;'">
                            <b-col sm="12" lg="8">
                              <i @click="f_openProductIngredients(product_item.product)" style="border-radius: 10px; padding-right: 5px; margin: 3px; padding-left: 5px; width: 20px; background-color: lightblue; text-align: center;">{{ (product_item_index + 1).toString() }}</i>
                              <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['1'] && product_item.product.data['0']['1']['lang'] && product_item.product.data['0']['1']['lang'][StoreLang]">
                                <span>{{ product_item.product.data['0']['1']['lang'][StoreLang] }}</span>
                              </template>
                              <template v-else-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['1'] && product_item.product.data['0']['1']['val']">
                                <span>{{ product_item.product.data['0']['1']['val'] }}</span>
                              </template>
                              {{ 'L' + product_item.product.li + '_' + product_item.product.id }}
                            </b-col>
                            <b-col sm="12" lg="2"> {{ f_calculateProductTotalPrice([product_item]) }} </b-col>
                            <b-col sm="12" lg="2">
                              <template v-if="product_item.loading">
                                <b-spinner variant="primary" label="Spinning"></b-spinner>
                              </template>
                              <template v-else>
                                <b-dropdown variant="white" size="sm" style="width: 100%; float: left;">
                                  <template slot="button-content">
                                    <template v-if="product_item.product.data['0']['0'] && product_item.product.data['0']['0']['val'] && product_item.product.data['0']['0']['val']['value']">
                                      {{ f_getOptionLabel(product_item.product.data['0']['0']['val']['value'], d_usrShpcrtBundProWdm['0']['parameters']['0']['options']) }}
                                    </template>
                                  </template>
                                  <b-dropdown-item @click="f_openPaymentScreen([product_item], bundle_item.user_shopcart_bundle_data.client_id)"><i class="fa fa-dollar"></i> {{ StoreLangTranslation.data['product_payment_screen'][StoreLang] }} </b-dropdown-item>
                                  <template v-for="(product_status, product_status_index) in f_getEligibleBundleProductStatus(product_item.product)">
                                    <b-dropdown-item @click="f_changeBundleProductStatus(user_item.user_c_id, product_status, bundle_item.user_shopcart_bundle_data.client_id, product_item.product.client_id, user_item_index, bundle_item_index, product_item_index)"><i class="fa fa-edit"></i> {{ product_status.translation[StoreLang] }} </b-dropdown-item>
                                  </template>
                                </b-dropdown>
                              </template>
                            </b-col>
                          </b-row>
                          <template v-for="(payment_item, payment_index) in product_item.payment_list">
                              <b-row :style="StoreDevice.isMobile ? 'cursor: pointer; border-bottom: solid 1px #dcdcdc; margin-left: 30px; font-size: 12px;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc; margin-left: 30px; font-size: 12px;'">
                              <b-col sm="12" lg="1" style="padding: 2px; text-align: right;">
                                <i @click="f_openProductPaymentIngredients(payment_item)" style="border-radius: 10px; padding-right: 5px; margin: 3px; padding-left: 5px; width: 20px; background-color: #ade6ad; text-align: center;">{{ (payment_index + 1).toString() }}</i>
                              </b-col>
                              <b-col sm="12" lg="1" style="padding: 2px;">
                                <template v-if="payment_item.data['0']['1'] && payment_item.data['0']['1']['val'] && payment_item.data['0']['1']['val']['value']">
                                  {{ f_getOptionLabel(payment_item.data['0']['1']['val']['value'], d_usrShpcrtBundProPayWdm['0']['parameters']['1']['options']) }}
                                </template>
                              </b-col>
                              <b-col sm="12" lg="2" style="padding: 2px;">
                                <template v-if="payment_item.data['0']['2'] && payment_item.data['0']['2']['val'] && payment_item.data['0']['2']['val']['value']">
                                  {{ f_getOptionLabel(payment_item.data['0']['2']['val']['value'], d_usrShpcrtBundProPayWdm['0']['parameters']['2']['options']) }}
                                </template>
                              </b-col>
                              <b-col sm="12" lg="1" style="padding: 2px;">
                                <template v-if="payment_item.data['0']['4'] && payment_item.data['0']['4']['val']">
                                  {{ payment_item.data['0']['4']['val'] }}
                                </template>
                                <template v-if="payment_item.data['0']['3'] && payment_item.data['0']['3']['val'] && payment_item.data['0']['3']['val']['value']">
                                  {{ f_getOptionLabel(payment_item.data['0']['3']['val']['value'], d_usrShpcrtBundProPayWdm['0']['parameters']['3']['options']) }}
                                </template>
                              </b-col>
                              <b-col sm="12" lg="2">
                                <template v-if="payment_item.data['0']['17'] && payment_item.data['0']['17']['val']">
                                  {{ payment_item.data['0']['17']['val'] }}
                                </template>
                              </b-col>
                              <b-col sm="12" lg="2">
                                <b-dropdown variant="white" size="sm" style="width: 100%; float: left; font-size: 12px;">
                                  <template slot="button-content">
                                    <template v-if="payment_item.data['0']['0'] && payment_item.data['0']['0']['val'] && payment_item.data['0']['0']['val']['value']">
                                      <span style="font-size: 12px;"> {{ f_getOptionLabel(payment_item.data['0']['0']['val']['value'], d_usrShpcrtBundProPayWdm['0']['parameters']['0']['options']) }} </span>
                                    </template>
                                  </template>
                                  <b-dropdown-item @click="f_editPayment(product_item, payment_index, bundle_item.user_shopcart_bundle_data.client_id)"><i class="fa fa-edit"></i> {{ StoreLangTranslation.data['edit'][StoreLang] }} </b-dropdown-item>
                                  <template v-for="(payment_status, payment_status_index) in f_getEligibleBundleProductPaymentStatus(payment_item)">
                                    <b-dropdown-item @click="f_changeBundleProductPaymentStatus(payment_status, bundle_item.user_shopcart_bundle_data.client_id, product_item.product.client_id, payment_item.client_id)"><i class="fa fa-edit"></i> {{ payment_status.translation[StoreLang] }} </b-dropdown-item>
                                  </template>
                                </b-dropdown>
                              </b-col>
                            </b-row>
                          </template>
                        </template>
                      </template>
                    </b-card-body>
                  </b-collapse>
                  
                 </b-card>
              </b-col>
            </b-row>
          </template>
          
      </b-card>

      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-modal v-if="d_selectedProductData.show" id="d_selectedProductData_show" v-model="d_selectedProductData.show" scrollable hide-footer :title="StoreLangTranslation.data['product_ingredients'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="lg">
      <p>
        <b-row>
          <b-col sm="12" lg="12">
            <shopcard-bundle-product-show :p_productData="d_selectedProductData.data"></shopcard-bundle-product-show>
          </b-col>
        </b-row>
      </p>
      <p>
      <b-row>
          <b-col sm="12" lg="6"></b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
          </b-col>
        </b-row>
      </p>
    </b-modal>
    <b-modal v-if="d_selectedProductPaymentData.show" id="d_selectedProductPaymentData_show" v-model="d_selectedProductPaymentData.show" scrollable hide-footer :title="StoreLangTranslation.data['payment_details'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="lg">
      <p>
        <b-row>
          <b-col sm="12" lg="12">
            <shopcard-bundle-product-payment-show :p_paymentData="d_selectedProductPaymentData.data"></shopcard-bundle-product-payment-show>
          </b-col>
        </b-row>
      </p>
      <p>
      <b-row>
          <b-col sm="12" lg="6"></b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
          </b-col>
        </b-row>
      </p>
    </b-modal>
    <b-modal v-if="d_selectedProductListForPayment.show" id="d_selectedProductData_show" v-model="d_selectedProductListForPayment.show" scrollable hide-footer :title="StoreLangTranslation.data['product_payment_screen'][StoreLang]" centered header-bg-variant="white" header-text-variant="dark" size="xl">
      <p>
        <b-row v-if="!StoreDevice.isMobile" style="border-bottom: solid 1px #dcdcdc; background-color: #ee99c1; font-size: 12px;">
          <b-col sm="12" lg="6" style="padding: 2px;">{{ StoreLangTranslation.data['product'][StoreLang] }}</b-col>
          <b-col sm="12" lg="1" style="padding: 2px;">{{ StoreLangTranslation.data['price'][StoreLang] }}</b-col>
          <b-col sm="12" lg="1" style="padding: 2px;">{{ d_usrShpcrtBundProPayWdm['0']['parameters']['17'].name.translation[StoreLang] }} </b-col>
          <b-col sm="12" lg="1" style="padding: 2px;">{{ d_usrShpcrtBundProPayWdm['0']['parameters']['1'].name.translation[StoreLang] }} </b-col>
          <b-col sm="12" lg="1" style="padding: 2px;">{{ d_usrShpcrtBundProPayWdm['0']['parameters']['2'].name.translation[StoreLang] }} </b-col>
          <b-col sm="12" lg="1" style="padding: 2px;">{{ d_usrShpcrtBundProPayWdm['0']['parameters']['3'].name.translation[StoreLang] }} </b-col>
          <b-col sm="12" lg="1" style="padding: 2px;">{{ d_usrShpcrtBundProPayWdm['0']['parameters']['4'].name.translation[StoreLang] }} </b-col>
        </b-row>
        <template v-if="!StoreDevice.isMobile" v-for="(product_item, product_item_index) in d_selectedProductListForPayment.product_list">
          <b-row style="border-bottom: solid 1px #dcdcdc; font-size: 12px;">
            <b-col sm="12" lg="6" style="padding: 2px;">
              <i @click="f_openProductIngredients(product_item.product)" style="border-radius: 10px; padding-right: 5px; margin: 3px; padding-left: 5px; width: 20px; background-color: lightblue; text-align: center;">{{ (product_item_index + 1).toString() }}</i>
              <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['1'] && product_item.product.data['0']['1']['lang'] && product_item.product.data['0']['1']['lang'][StoreLang]">
                <span>{{ product_item.product.data['0']['1']['lang'][StoreLang] }}</span>
              </template>
              <template v-else-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['1'] && product_item.product.data['0']['1']['val']">
                <span>{{ product_item.product.data['0']['1']['val'] }}</span>
              </template>
            </b-col>
            <b-col sm="12" lg="1" style="padding: 2px;"> {{ f_calculateProductTotalPrice([product_item]) }} </b-col>
            <b-col sm="12" lg="1" style="padding: 2px;">
              <b-form-input class="form-control" type="date" v-model="product_item.payment.pay_date" style="font-size: 11px; padding: 2px;"></b-form-input>
            </b-col>
            <b-col sm="12" lg="1" style="padding: 2px;">
              <select class="form-control" v-model="product_item.payment.pay_type" :title="d_usrShpcrtBundProPayWdm['0']['parameters']['1'].name.translation[StoreLang]" style="font-size: 11px; padding: 2px;">
                <option v-for="(op, op_index) in d_usrShpcrtBundProPayWdm['0']['parameters']['1'].options" :value="op.value">
                  {{ op.translation[StoreLang] }}
                </option>
              </select>
            </b-col>
            <b-col sm="12" lg="1" style="padding: 2px;">
              <select class="form-control" v-model="product_item.payment.pay_method" :title="d_usrShpcrtBundProPayWdm['0']['parameters']['2'].name.translation[StoreLang]" style="font-size: 11px; padding: 2px;">
                <option v-for="(op, op_index) in d_usrShpcrtBundProPayWdm['0']['parameters']['2'].options" :value="op.value">
                  {{ op.translation[StoreLang] }}
                </option>
              </select>
            </b-col>
            <b-col sm="12" lg="1" style="padding: 2px;">
              <select class="form-control" v-model="product_item.payment.currency_type" :title="d_usrShpcrtBundProPayWdm['0']['parameters']['3'].name.translation[StoreLang]" style="font-size: 11px; padding: 2px;">
                <option v-for="(op, op_index) in d_usrShpcrtBundProPayWdm['0']['parameters']['3'].options" :value="op.value">
                  {{ op.translation[StoreLang] }}
                </option>
              </select>
            </b-col>
            <b-col sm="12" lg="1" style="padding: 2px;">
              <b-form-input class="form-control" type="number" v-model="product_item.payment.currency_amount" style="font-size: 11px; padding: 2px;"></b-form-input>
            </b-col>
          </b-row>
        </template>
        <template v-if="StoreDevice.isMobile" v-for="(product_item, product_item_index) in d_selectedProductListForPayment.product_list">
          <b-row style="border-bottom: solid 1px #dcdcdc; background-color: #ee99c1;">
            <b-col sm="12" lg="8">
              <i @click="f_openProductIngredients(product_item.product)" style="border-radius: 10px; padding-right: 5px; margin: 3px; padding-left: 5px; width: 20px; background-color: lightblue; text-align: center;">{{ (product_item_index + 1).toString() }}</i>
              <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['1'] && product_item.product.data['0']['1']['lang'] && product_item.product.data['0']['1']['lang'][StoreLang]">
                <span>{{ product_item.product.data['0']['1']['lang'][StoreLang] }}</span>
              </template>
              <template v-else-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['1'] && product_item.product.data['0']['1']['val']">
                <span>{{ product_item.product.data['0']['1']['val'] }}</span>
              </template>
            </b-col>
            <b-col sm="12" lg="2"> {{ f_calculateProductTotalPrice([product_item]) }} </b-col>
            <b-col sm="12" lg="2"></b-col>
          </b-row>
          <b-row style="margin-left: 20px; padding: 5px;">
            <b-col sm="12" lg="6">
              {{ d_usrShpcrtBundProPayWdm['0']['parameters']['17'].name.translation[StoreLang] }}
            </b-col>
            <b-col sm="12" lg="6">
              <b-form-input class="form-control" type="date" v-model="product_item.payment.pay_date" style="font-size: 11px; padding: 2px;"></b-form-input>
            </b-col>
          </b-row>
          <b-row style="margin-left: 20px; padding: 5px;">
            <b-col sm="12" lg="6">
              {{ d_usrShpcrtBundProPayWdm['0']['parameters']['1'].name.translation[StoreLang] }}
            </b-col>
            <b-col sm="12" lg="6">
              <select class="form-control" v-model="product_item.payment.pay_type" :title="d_usrShpcrtBundProPayWdm['0']['parameters']['1'].name.translation[StoreLang]" style="font-size: 11px; padding: 2px;">
                <option v-for="(op, op_index) in d_usrShpcrtBundProPayWdm['0']['parameters']['1'].options" :value="op.value">
                  {{ op.translation[StoreLang] }}
                </option>
              </select>
            </b-col>
          </b-row>
          <b-row style="margin-left: 20px; padding: 5px;">
            <b-col sm="12" lg="6">
              {{ d_usrShpcrtBundProPayWdm['0']['parameters']['2'].name.translation[StoreLang] }}
            </b-col>
            <b-col sm="12" lg="6">
              <select class="form-control" v-model="product_item.payment.pay_method" :title="d_usrShpcrtBundProPayWdm['0']['parameters']['2'].name.translation[StoreLang]" style="font-size: 11px; padding: 2px;">
                <option v-for="(op, op_index) in d_usrShpcrtBundProPayWdm['0']['parameters']['2'].options" :value="op.value">
                  {{ op.translation[StoreLang] }}
                </option>
              </select>
            </b-col>
          </b-row>
          <b-row style="margin-left: 20px; padding: 5px;">
            <b-col sm="12" lg="6">
              {{ d_usrShpcrtBundProPayWdm['0']['parameters']['3'].name.translation[StoreLang] }}
            </b-col>
            <b-col sm="12" lg="6">
              <select class="form-control" v-model="product_item.payment.currency_type" :title="d_usrShpcrtBundProPayWdm['0']['parameters']['3'].name.translation[StoreLang]" style="font-size: 11px; padding: 2px;">
                <option v-for="(op, op_index) in d_usrShpcrtBundProPayWdm['0']['parameters']['3'].options" :value="op.value">
                  {{ op.translation[StoreLang] }}
                </option>
              </select>
            </b-col>
          </b-row>
          <b-row style="margin-left: 20px; padding: 5px;">
            <b-col sm="12" lg="6">
              {{ d_usrShpcrtBundProPayWdm['0']['parameters']['4'].name.translation[StoreLang] }}
            </b-col>
            <b-col sm="12" lg="6">
              <b-form-input class="form-control" type="number" v-model="product_item.payment.currency_amount" style="font-size: 11px; padding: 2px;"></b-form-input>
            </b-col>
          </b-row>
        </template>
      </p>
      <p>
        <b-row style="font-size: 12px;">
          <b-col sm="12" lg="12" style="text-align: right;">
            <b-button @click="f_recordPayment()" variant="white" :disabled="d_saveNewPaymentLoading">
                <i class="fa fa-send-o"></i>
                <template v-if="d_selectedProductListForPayment.mode === 'new'">
                  {{ StoreLangTranslation.data['save_new_payment_data'][StoreLang] }}
                </template>
                <template v-else-if="d_selectedProductListForPayment.mode === 'edit'">
                  {{ StoreLangTranslation.data['update_payment_data'][StoreLang] }}
                </template>
                <b-spinner v-if="d_saveNewPaymentLoading" variant="primary" label="Spinning"></b-spinner>
              </b-button>
          </b-col>
        </b-row>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { ClsWdm } from '@/wam/modules/wdm';
import moment from "moment";
import store from '@/store';
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import {
  default as ShopcardBundleProductShow
} from '@/components/widgets/ShopcardBundleProductShow';
import {
  default as UserSearch
} from '@/wam/components/widgets/UserSearch';
import {
  default as ShopcardBundleProductPaymentShow
} from '@/components/widgets/ShopcardBundleProductPaymentShow';
import { mapGetters } from 'vuex';
export default {
  name: 'user_shop_order_list',
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreShopCartOrderList: 'StoreShopCartOrderList',
    StoreCurrencyType: 'StoreCurrencyType',
  }),
  components: {
    PageInfo,
    ShopcardBundleProductShow,
    ShopcardBundleProductPaymentShow,
    UserSearch,
  },
  props: {},
  data () {
    return {
      d_dataPrepareType: 'wdmr_list_id',
      d_saveNewPaymentLoading: false,
      d_bundlesLoading: false,
      d_addToShopcartLoading: false,
      d_selectedBundleId: '',
      d_shopWdmrId: '',
      d_selectedBundleGroup: '',
      d_productList: '',
      d_filterMode: 'query',
      d_userSearchResult: {
        'selected_index': '',
        'search_text': '',
        'list': [],
      },
      d_searchMode: {
        'field_type': 'username', // username, all, mail, ve benzeri, smar
        'match_type': 'full' // full, include
      },
      d_showDates: false,
      d_filterLoading: false,
      d_selectedProductListForPayment: {
        'user_shopcart_bundle_client_id': '',
        'product_list': [],
        'show': false,
        'mode': '',
      },
      d_selectedProductPaymentData: {
        'data': '',
        'show': false
      },
      d_selectedProductData: {
        'data': '',
        'show': false
      },
      d_paymentModal: {
        'data': '',
        'show': false
      },
      d_pagination: {
        'service_triggered': 'no',
        'field_option_id': '',
        'field_option_list_id': '',
        'field_process_list': '',
        'wdmr_list_id': '',
        'wdmr_list_id_count': '',
        'last_field_option_list_id': '',
        'first_field_option_list_id': '',
        'next_page_eligible': 'no',
        'order_type': 'asc',
      },
      d_filterData: {
        "filter_operation_type": "and",
        "paramorder": [
          "user_shopcart_bundle_filter",
          "user_shopcart_bundle_product_filter",
          "user_shopcart_bundle_product_payment_filter"
        ],
        "parameters": {
          "user_shopcart_bundle_filter": {
            "background_color": "#d7f3d7",
            "layer_code": "usr_shpcrt_bund",
            "wdm": "",
            "paramorder": ["status", "shopcart_add", "order", "cancel", "active", "termination", "passive"],
            "parameters": {
              "status": {"value": "2", "wdm_param": "0", "all_values": ["0", "1", "2", "3", "4", "5", "6"]},
              "shopcart_add": {"use": "no", "wdm_param": "8", "start": "", "end": ""},
              "order": {"use": "no", "wdm_param": "9", "start": "", "end": ""},
              "cancel": {"use": "no", "wdm_param": "10", "start": "", "end": ""},
              "active": {"use": "no", "wdm_param": "11", "start": "", "end": ""},
              "termination": {"use": "no", "wdm_param": "12", "start": "", "end": ""},
              "passive": {"use": "no", "wdm_param": "13", "start": "", "end": ""},
            }
          },
          "user_shopcart_bundle_product_filter": {
            "background_color": "#f4f4c0",
            "layer_code": "usr_shpcrt_bund_pro",
            "wdm": "",
            "paramorder": ["status", "order", "cancel", "termination", "product_preparation", "sending_the_product_to_the_customer", "product_return", "active", "passive"],
            "parameters": {
              "status": {"value": "", "wdm_param": "0", "all_values": ["0", "1", "2", "3", "4", "5", "6", "7"]},
              "order": {"use": "no", "wdm_param": "32", "start": "", "end": ""},
              "cancel": {"use": "no", "wdm_param": "33", "start": "", "end": ""},
              "termination": {"use": "no", "wdm_param": "34", "start": "", "end": ""},
              "product_preparation": {"use": "no", "wdm_param": "35", "start": "", "end": ""},
              "sending_the_product_to_the_customer": {"use": "no", "wdm_param": "36", "start": "", "end": ""},
              "product_return": {"use": "no", "wdm_param": "37", "start": "", "end": ""},
              "active": {"use": "no", "wdm_param": "38", "start": "", "end": ""},
              "passive": {"use": "no", "wdm_param": "39", "start": "", "end": ""},
            }
          },
          "user_shopcart_bundle_product_payment_filter": {
            "background_color": "#b0d7e6",
            "layer_code": "usr_shpcrt_bund_pro_pay",
            "wdm": "",
            "paramorder": ["status", "payment_waiting", "payment_approved", "bill_completed", "payment_cancel", "payment_refunded", "bill_cancelled"],
            "parameters": {
              "status": {"value": "", "wdm_param": "0", "all_values": ["0", "1", "2", "3", "4", "5"]},
              "payment_waiting": {"use": "no", "wdm_param": "5", "start": "", "end": ""},
              "payment_approved": {"use": "no", "wdm_param": "6", "start": "", "end": ""},
              "bill_completed": {"use": "no", "wdm_param": "7", "start": "", "end": ""},
              "payment_cancel": {"use": "no", "wdm_param": "8", "start": "", "end": ""},
              "payment_refunded": {"use": "no", "wdm_param": "9", "start": "", "end": ""},
              "bill_cancelled": {"use": "no", "wdm_param": "10", "start": "", "end": ""},
            }
          }
        }
      },
      d_currencyType: '0', // 0 tl, 1 dollar
      d_usrShpcrtBundProPayWdm: '',
      d_usrShpcrtBundProWdm: '',
      d_usrShpcrtBundWdm: '',
      d_user: '',
      d_hoverIndex: '',
      d_searchText: '',
      d_frontendClientID: '',
      d_frontendList: [],
      d_usrShpBundleList: [],
      d_shopOrderList: []
    };
  },
  created: function () {
    ClsWdm.getLayerWdm('usr_shpcrt_bund_pro_pay').then(resp_wdm_data => {
      this.d_usrShpcrtBundProPayWdm = resp_wdm_data;
      this.d_filterData.parameters.user_shopcart_bundle_product_payment_filter.wdm = this.d_usrShpcrtBundProPayWdm;
    });
    ClsWdm.getLayerWdm('usr_shpcrt_bund_pro').then(resp_wdm_data => {
      this.d_usrShpcrtBundProWdm = resp_wdm_data;
      this.d_filterData.parameters.user_shopcart_bundle_product_filter.wdm = this.d_usrShpcrtBundProWdm;
    });
    ClsWdm.getLayerWdm('usr_shpcrt_bund').then(resp_wdm_data => {
      this.d_usrShpcrtBundWdm = resp_wdm_data;
      this.d_filterData.parameters.user_shopcart_bundle_filter.wdm = this.d_usrShpcrtBundWdm;
    });
    this.d_user = JSON.parse(localStorage.getItem('user'));
    this.d_userShopClientID = this.$route.query.user_shop_client_id;
    // Sipariş modu filtre yapısında seçili. Dolayısıyla tetiklendiğinde siparişte olan bir ürün varsa sayfa açılırken görüntülüyor.
    if (this.d_user && this.d_user.username && this.d_user.auth.indexOf('admin') !== -1 && this.d_userShopClientID) {
      this.f_userShopOrderList('filter_data', 'asc', [], true);
      this.f_bundleAndProductListActive();
    }
  },
  beforeMount () {},
  mounted: function () {
    // console.log(this.StoreFrontendSettings);
  },
  methods: { 
    f_userShopOrderList: function (process_mode, order_type = '', user_shop_bundle_use_list = [], loading_active = false) {
      this.d_bundlesLoading = true;
      let is_eligible = false;
      let query = '';
      query += '&user_shop_client_id=' + this.d_userShopClientID;
      let data = {};
      if (this.d_filterMode === 'query') {
        if (process_mode === 'filter_data') {
          for (let group_index in this.d_filterData.paramorder) {
            let group_value = this.d_filterData.paramorder[group_index];
            for (let param_index in this.d_filterData.parameters[group_value].paramorder) {
              let param_value = this.d_filterData.parameters[group_value].paramorder[param_index];
              let param_data = this.d_filterData.parameters[group_value].parameters[param_value];
              if (param_data.use !== undefined && param_data.start !== undefined) {
                if (param_data.use !== 'yes') {
                  param_data.start = '';
                  param_data.end = '';
                }
              }
            }
          }
          this.d_shopOrderList = [];
          this.d_pagination.order_type = order_type;
          data = {
            'data_prepare_type': this.d_dataPrepareType,
            'wdmr_list_id': this.d_pagination.wdmr_list_id,
            'filter_data': this.d_filterData,
            'field_option_id': this.d_pagination.field_option_id,
            'field_option_list_id': this.d_pagination.field_option_list_id,
            'field_process_list': this.d_pagination.field_process_list,
            'order_type': order_type,
          };
        } else if (process_mode === 'user_shop_bundle_use_list') {
          data = {
            'user_shop_bundle_use_list': user_shop_bundle_use_list
          };
        }
        is_eligible = true;
      } else if (this.d_filterMode === 'username') {
        if (this.d_userSearchResult.list.length > 0 && this.d_userSearchResult.selected_index !== '') {
          query += '&username=' + this.d_userSearchResult.list[this.d_userSearchResult.selected_index].username;
          is_eligible = true;
        }
      }
      if (is_eligible) {
        if (loading_active) {
          this.d_filterLoading = true;
        }
        ServiceFunctions.user_shop_order_list(query, data)
          .then(resp => {
            this.d_bundlesLoading = false;
            this.d_filterLoading = false;
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              if (resp.data.list) {
                this.d_shopOrderList = resp.data.list;
                if (process_mode === 'filter_data') {
                  this.d_pagination.service_triggered = 'yes';
                  if (this.d_dataPrepareType === 'data_profile') {
                    this.d_pagination.field_option_id = resp.data.field_option_id;
                    this.d_pagination.field_option_list_id = resp.data.field_option_list_id;
                    if (resp.data.field_process_list && resp.data.field_process_list.option_id_list){
                      this.d_pagination.field_process_list = resp.data.field_process_list;
                      // If the last option item is not equal to the completed field option list id, pagination can continue.
                      let first_field_option_list_id = this.d_pagination.field_process_list.option_id_list[0].list_id;
                      this.d_pagination.first_field_option_list_id = first_field_option_list_id;

                      let last_field_option_list_id = this.d_pagination.field_process_list.option_id_list[this.d_pagination.field_process_list.option_id_list.length - 1].list_id;
                      this.d_pagination.last_field_option_list_id = last_field_option_list_id;

                      if (this.d_pagination.field_option_list_id) {
                        if (last_field_option_list_id !== this.d_pagination.field_option_list_id && first_field_option_list_id !== this.d_pagination.field_option_list_id)
                        this.d_pagination.next_page_eligible = 'yes';
                      }
                    }
                  } else if (this.d_dataPrepareType === 'wdmr_list_id') {
                    this.d_pagination.wdmr_list_id = resp.data.wdmr_list_id;
                    this.d_pagination.wdmr_list_id_count = resp.data.wdmr_list_id_count;
                    if (this.d_pagination.wdmr_list_id < this.d_pagination.wdmr_list_id_count) {
                      this.d_pagination.next_page_eligible = 'yes';
                    }
                  }
                } else if (process_mode === 'user_shop_bundle_use_list') {
                  //
                }

              } else {
                alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
              }
            } else {
              alert(resp.data.status_code + ' = ' + resp.data.status_message);
            }
          });
      }
    },
    f_bundleAndProductListActive: function () {
      this.d_loading = true;
      let query = 'user_shop_client_id=' + this.d_userShopClientID;
      let data = {};
      ServiceFunctions.bundle_and_product_list_active(query, data)
        .then(resp => {
          this.d_loading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_productList = resp.data.list;
              this.d_shopWdmrId = Object.keys(this.d_productList.parameters)[0];
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_userShopCartBundleAdd: function () {
      if (this.d_userSearchResult.list.length > 0 && this.d_userSearchResult.selected_index !== '') {
        if (this.d_selectedBundleId) {
          let user_client_id = this.d_userSearchResult.list[this.d_userSearchResult.selected_index].username;
          this.d_addToShopcartLoading = true;
          let query = 'user_client_id=' + user_client_id;
          query += '&add_bundle_count=1';
          query += '&user_shop_client_id=' + this.d_userShopClientID;
          query += '&user_shop_bundle_client_id=' + this.d_productList.parameters[this.d_shopWdmrId].parameters[this.d_selectedBundleId].data.client_id;
          let data = {};
          ServiceFunctions.user_shopcart_bundle_add(query, data)
            .then(resp => {
              if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
                this.f_userShopcartBundleProductOrder(user_client_id);
              } else {
                this.d_addToShopcartLoading = false;
                alert(resp.data.status_code + ' = ' + resp.data.status_message);
              }
            });
        }
      }
    },
    f_userShopcartBundleProductOrder: function (user_client_id) {
      let query = 'user_client_id=' + user_client_id;
      let data = {};
      ServiceFunctions.user_shopcart_bundle_product_order(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.order_status === 'completed') {
              this.d_addToShopcartLoading = false;
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_dateFormat: function (date, date_type='year-month-day') {
      if (date) {
        if (date_type === 'year-month-day') {
          if (this.StoreLang === 'tr') {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
            return x;
          } else {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("YYYY/MM/DD");
            return x;
          }
        } else if (date_type === 'year-month-day hh:mm') {
          let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm");
          return x;
        }
      } else {
        return "";
      }
    },
    f_getEligibleBundleProductPaymentStatus: function (pay_data) {
      let pay_last_status = '';
      let pay_option_list = [];
      try {
        pay_last_status = pay_data.data['0']['0']['val']['value'];
      } catch(err) {}
      for (let option_index in this.d_usrShpcrtBundProPayWdm['0']['parameters']['0']['options']) {
        if (pay_last_status !== this.d_usrShpcrtBundProPayWdm['0']['parameters']['0']['options'][option_index].value) {
          pay_option_list.push(this.d_usrShpcrtBundProPayWdm['0']['parameters']['0']['options'][option_index]);
        }
      }
      return pay_option_list;
    },
    f_getEligibleBundleProductStatus: function (product_data) {
      let product_last_status = '';
      let product_option_list = [];
      try {
        product_last_status = product_data.data['0']['0']['val']['value'];
      } catch(err) {}
      for (let option_index in this.d_usrShpcrtBundProWdm['0']['parameters']['0']['options']) {
        if (product_last_status !== this.d_usrShpcrtBundProWdm['0']['parameters']['0']['options'][option_index].value) {
          product_option_list.push(this.d_usrShpcrtBundProWdm['0']['parameters']['0']['options'][option_index]);
        }
      }
      return product_option_list;
    },
    f_getEligibleBundleStatus: function (shopcart_bundle_data) {
      let bundle_last_status = '';
      try {
        bundle_last_status = shopcart_bundle_data.data['0']['0']['val']['value'];
      } catch(err) {}
      let bundle_option_list = [];
      for (let option_index in this.d_usrShpcrtBundWdm['0']['parameters']['0']['options']) {
        if (bundle_last_status !== this.d_usrShpcrtBundWdm['0']['parameters']['0']['options'][option_index].value) {
          bundle_option_list.push(this.d_usrShpcrtBundWdm['0']['parameters']['0']['options'][option_index]);
        }
      }
      return bundle_option_list;
    },
    f_getOptionLabel: function (option_value, options) {
      // console.log('option_value : ', option_value);
      // console.log('options : ', options);
      let option_label = '';
      for (let i in options) {
        if (options[i].value === option_value) {
          option_label = options[i].translation[this.StoreLang];
          break;
        }
      }
      return option_label;
    },
    f_resetFilterData: function () {
      this.d_pagination.field_option_id = '';
      this.d_pagination.field_option_list_id = '';
      this.d_pagination.field_process_list = '';
      this.d_pagination.last_field_option_list_id = '';
      this.d_pagination.first_field_option_list_id = '';
      this.d_pagination.next_page_eligible = 'no';
      this.d_pagination.order_type = '';
      this.d_pagination.service_triggered = 'no';
      this.d_shopOrderList = [];
    },
    f_openPaymentScreen: function (product_list, user_shopcart_bundle_client_id) {
      this.d_selectedProductListForPayment.product_list = product_list;
      this.d_selectedProductListForPayment.user_shopcart_bundle_client_id = user_shopcart_bundle_client_id;
      this.d_selectedProductListForPayment.show = true;
      this.d_selectedProductListForPayment.mode = 'new';
    },
    f_editPayment: function (product_item, payment_index, user_shopcart_bundle_client_id) {
      this.d_selectedProductListForPayment.mode = 'edit';
      let pro_item = JSON.parse(JSON.stringify(product_item));
      /*
        product_item = {
          "product": {seçilen ürün wdmr veri modeli},
          "payment_list": [{ her bir ödeme wdmr veri modeli}, ...],
          "payment": {
            'pay_wdmr_id': '',
            'pay_client_id': '',
            'pay_date': str(date.today()),
            'pay_type': '0',
            'pay_method': '2',
            'currency_type': '1',
            'currency_amount': 0,
          }
        }
      */
      let payment_wdmr = product_item.payment_list[payment_index];
      let today = new Date().toISOString().slice(0, 10)
      // console.log("today : ", today);
      let pay_date = today;
      try {
        pay_date = payment_wdmr['data']['0']['17']['val']
      } catch(err) {}

      let pay_type = '0';
      try {
        pay_type = payment_wdmr['data']['0']['1']['val']['value']
      } catch(err) {}

      let pay_method = '2';
      try {
        pay_method = payment_wdmr['data']['0']['2']['val']['value']
      } catch(err) {}

      let currency_type = '1';
      try {
        currency_type = payment_wdmr['data']['0']['3']['val']['value']
      } catch(err) {}

      let currency_amount = 0;
      try {
        currency_amount = payment_wdmr['data']['0']['4']['val']
      } catch(err) {}

      let edit_payment = {
        'pay_wdmr_id': payment_wdmr.id,
        'pay_client_id': payment_wdmr.client_id,
        'pay_date': pay_date,
        'pay_type': pay_type,
        'pay_method': pay_method,
        'currency_type': currency_type,
        'currency_amount': currency_amount,
      };
      let new_pro_item = {
        'product': pro_item.product,
        'payment_list': pro_item.payment_list,
        'payment': edit_payment
      };
      let product_list = [];
      product_list.push(new_pro_item);
      this.d_selectedProductListForPayment.product_list = product_list;
      this.d_selectedProductListForPayment.user_shopcart_bundle_client_id = user_shopcart_bundle_client_id;
      this.d_selectedProductListForPayment.show = true;
    },
    f_openProductIngredients: function (product_data) {
      this.d_selectedProductData.show = true;
      this.d_selectedProductData.data = product_data;
    },
    f_openProductPaymentIngredients: function (payment_data) {
      this.d_selectedProductPaymentData.show = true;
      this.d_selectedProductPaymentData.data = payment_data;
    },
    f_searchUserShopBundle: function (usr_shp_bund) {
      let res = false;
      if (this.d_searchText) {
        if (usr_shp_bund.w_id.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
          res = true;
        }
      } else {
        res = true;
      }
      return res;
    },
    f_calculateProductTotalPrice: function (bundle_products) {
      let currency_text = this.StoreCurrencyType.label;
      let total_amount = 0;
      for (let product_index in bundle_products) {
        try {
          for (let currency_amount_index in bundle_products[product_index].product.data['0']['25'].list) {
            let currency_amount_item = bundle_products[product_index].product.data['0']['25'].list[currency_amount_index];
            try {
              if (currency_amount_item['26']['val']['value'] === this.StoreCurrencyType.value) {
                total_amount += parseFloat(currency_amount_item['27']['val']);
              }
            } catch(err) {}
          }
        } catch(err) {}
      }
      return total_amount + ' ' + currency_text;
    },
    f_recordPayment: function () {
      this.d_saveNewPaymentLoading = true;
      let query = '';
      query += 'user_shop_client_id=' + this.d_userShopClientID;
      let product_payment_list = [];
      // Şu an tek bir paketin ürünleri için kayıt oluşturacak şekilde gönderiyoruz. Fakat daha sonra çoklu paket için ürün güncelleme gönderebilecek şekilde backend geliştirme yapıldı.
      for (let product_index in this.d_selectedProductListForPayment.product_list) {
        let new_payment_item = {
          'user_shopcart_bundle_client_id': this.d_selectedProductListForPayment.user_shopcart_bundle_client_id,
          'user_shopcart_bundle_product_client_id': this.d_selectedProductListForPayment.product_list[product_index].product.client_id,
          'payment': this.d_selectedProductListForPayment.product_list[product_index].payment
        };
        product_payment_list.push(new_payment_item);
      }
      let data = {
        'product_payment_list': product_payment_list
      };
      ServiceFunctions.user_shopcart_bundle_payment_record(query, data)
        .then(resp => {
          this.d_saveNewPaymentLoading = false;
          this.d_selectedProductListForPayment.show = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            let user_shop_bundle_use_list = [];
            for (let user_item_index in this.d_shopOrderList) {
              for (let bundle_item_index in this.d_shopOrderList[user_item_index].bundle_list) {
                let x = this.d_shopOrderList[user_item_index].bundle_list[bundle_item_index];
                user_shop_bundle_use_list.push(x.user_shopcart_bundle_data.client_id);
              }
            }
            if (user_shop_bundle_use_list.length > 0) {
              this.f_userShopOrderList('user_shop_bundle_use_list', '', user_shop_bundle_use_list, false);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_changeBundleStatus: function (bundle_status, user_shopcart_bundle_client_id, user_item_index, bundle_item_index) {
      this.d_shopOrderList[user_item_index].bundle_list[bundle_item_index].loading = true;
      let query = '';
      query += '&user_shop_client_id=' + this.d_userShopClientID;
      query += '&user_shopcart_bundle_client_id=' + user_shopcart_bundle_client_id;
      query += '&status=' + bundle_status.value;
      let data = {};
      ServiceFunctions.user_shopcart_bundle_status_change(query, data)
        .then(resp => {
          this.d_shopOrderList[user_item_index].bundle_list[bundle_item_index].loading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            let user_shop_bundle_use_list = [];
            for (let user_item_index in this.d_shopOrderList) {
              for (let bundle_item_index in this.d_shopOrderList[user_item_index].bundle_list) {
                let x = this.d_shopOrderList[user_item_index].bundle_list[bundle_item_index];
                user_shop_bundle_use_list.push(x.user_shopcart_bundle_data.client_id);
              }
            }
            if (user_shop_bundle_use_list.length > 0) {
              this.f_userShopOrderList('user_shop_bundle_use_list', '', user_shop_bundle_use_list, false);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_changeBundleProductStatus: function (user_client_id, product_status, user_shopcart_bundle_client_id, user_shopcart_bundle_product_client_id, user_item_index, bundle_item_index, product_item_index) {
      this.d_shopOrderList[user_item_index].bundle_list[bundle_item_index].product_list[product_item_index].loading = true;
      let query = 'user_client_id=' + user_client_id;
      query += '&user_shop_client_id=' + this.d_userShopClientID;
      query += '&user_shopcart_bundle_client_id=' + user_shopcart_bundle_client_id;
      query += '&user_shopcart_bundle_product_client_id=' + user_shopcart_bundle_product_client_id;
      query += '&status=' + product_status.value;
      let data = {};
      ServiceFunctions.user_shopcart_bundle_product_status_change(query, data)
        .then(resp => {
          this.d_shopOrderList[user_item_index].bundle_list[bundle_item_index].product_list[product_item_index].loading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            let user_shop_bundle_use_list = [];
            for (let user_item_index in this.d_shopOrderList) {
              for (let bundle_item_index in this.d_shopOrderList[user_item_index].bundle_list) {
                let x = this.d_shopOrderList[user_item_index].bundle_list[bundle_item_index];
                user_shop_bundle_use_list.push(x.user_shopcart_bundle_data.client_id);
              }
            }
            if (user_shop_bundle_use_list.length > 0) {
              this.f_userShopOrderList('user_shop_bundle_use_list', '', user_shop_bundle_use_list, false);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_changeBundleProductPaymentStatus: function (payment_status, user_shopcart_bundle_client_id, user_shopcart_bundle_product_client_id, user_shopcart_bundle_product_payment_client_id) {
      let query = '';
      query += '&user_shop_client_id=' + this.d_userShopClientID;
      query += '&user_shopcart_bundle_client_id=' + user_shopcart_bundle_client_id;
      query += '&user_shopcart_bundle_product_client_id=' + user_shopcart_bundle_product_client_id;
      query += '&user_shopcart_bundle_product_payment_client_id=' + user_shopcart_bundle_product_payment_client_id;
      query += '&status=' + payment_status.value;
      let data = {};
      ServiceFunctions.user_shopcart_bundle_product_payment_status_change(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            let user_shop_bundle_use_list = [];
            for (let user_item_index in this.d_shopOrderList) {
              for (let bundle_item_index in this.d_shopOrderList[user_item_index].bundle_list) {
                let x = this.d_shopOrderList[user_item_index].bundle_list[bundle_item_index];
                user_shop_bundle_use_list.push(x.user_shopcart_bundle_data.client_id);
              }
            }
            if (user_shop_bundle_use_list.length > 0) {
              this.f_userShopOrderList('user_shop_bundle_use_list', '', user_shop_bundle_use_list, false);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
  },
  watch: {}
};

</script>

